import CommonCss from "nlib/common/common.module.scss";

import Css from "./style.module.scss";

import * as Icons from "@phosphor-icons/react";
import { Redirect } from "react-router-dom/cjs/react-router-dom";
import { checkDocumentsFetching } from "selectors/documents";
import {
  checkMatchesFetching,
  checkSelectedBusinessHasBusinessUsers,
  checkSelectedBusinessRpaMode,
  getSelectedBusinessId
} from "selectors/businesses";
import { getActiveOrganization } from "selectors/organizations";
import { getTransactionsStats } from "selectors/transactions";
import { getUserData } from "selectors/user";
import { useSelector } from "react-redux";
import AskClientPopup from "nlib/common/AskClientPopup";
import BulkActions from "./lib/BulkActions";
import Comments from "./lib/Comments";
import Constants from "const/Constants";
import Countries from "const/Countries";
import EmptyState from "./lib/EmptyState";
import Filters from "./lib/Filters";
import ListItem from "./lib/ListItem";
import ListItemDetails from "./lib/ListItemDetails";
import Pagination from "nlib/ui/Pagination";
import Preloader from "nlib/common/Preloader";
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import RpaBulkActions from "./lib/RpaBulkActions";
import TransactionsContext from "contexts/TransactionsContext";
import TransactionsPopup from "nlib/common/TransactionsPopup";
import TransactionsStatusFilter from "nlib/common/TransactionsStatusFilter";
import UiRoutes from "const/UiRoutes";
import Utils from "utils/Utils";
import classNames from "classnames";
import useEnvVars from "hooks/useEnvVars";
import useTransactions from "hooks/useTransactions";

const MAX_DISPLAYED_PAGES = 5;

const TransactionsPage = () => {
  const [envVars, setEnvVars] = useEnvVars();

  const {
    page = 1,
    limit = Constants.TABLE_PAGE_SIZE,
    editItem: editItemFallback,
    transactionId: editItem = editItemFallback
  } = envVars;

  const [editItemId] = editItem ? editItem.split(".") : [];

  const rpaMode = useSelector(checkSelectedBusinessRpaMode);

  const {
    hasFilters,
    popupMode,
    transactionsForPopup = [],
    selectedTransactions,
    selectedTransactionsData,
    editableTransactionsIds,
    transactionsReadyToProcess,
    transactionsReadyToReview,
    transactionsReadyToApprove,
    selectedNeedReactionIds,
    closedBookDateTransactionIds,
    transactionsFetching,
    transactionsData,
    transactionsState,
    setTransactionsState,
    refetchTransactions,
    setSelectedTransactions,
    onSelectedChange,
    onAskClientPopupClose,
    onBulkActionsEdit,
    onBulkActionsCancel,
    onTransactionsPopupSubmit
  } = useTransactions();

  const transactionsStats = useSelector(getTransactionsStats);

  const documentsFetching = useSelector(checkDocumentsFetching);

  const matchesFetching = useSelector(checkMatchesFetching);

  const { businessOrganization, countryCode } = useSelector(getActiveOrganization);

  const selectedBusinessHasBusinessUsers = useSelector(checkSelectedBusinessHasBusinessUsers);

  const userData = useSelector(getUserData);

  const businessId = useSelector(getSelectedBusinessId);

  const [localReasons, setLocalReasons] = useState([]);

  const [scrolled, setScrolled] = useState(false);

  const editTransactionData = Utils.arrayFindById(transactionsData, editItemId);

  const editTransactionState = Utils.arrayFindById(transactionsState, editItemId);

  const BulkActionsComponent = rpaMode ? RpaBulkActions : BulkActions;

  const czCountry = countryCode === Countries.CZ;

  const handleReasonBlur = useCallback(() => {
    const reasons = transactionsState.map((item) => item.reason).filter(Boolean);

    setLocalReasons(() => [...new Set(reasons)]);
  }, [transactionsState]);

  const fetchingData = transactionsFetching || documentsFetching || matchesFetching;

  const accounts = useMemo(() => {
    return transactionsData.reduce((result, transaction) => {
      if (!Utils.arrayFindById(result, transaction.account.id)) {
        result.push(transaction.account);
      }

      return result;
    }, []);
  }, [transactionsData]);

  const handlePageChange = useCallback((nextPage) => {
    setEnvVars({ page: nextPage });
  }, [setEnvVars]);

  const handleTransactionChange = useCallback((transactionId, update) => {
    setTransactionsState((prevState) =>
      Utils.arrayUpdateItemById(
        prevState,
        transactionId,
        (prev) => ({ ...prev, ...update })
      ));
  }, [setTransactionsState]);

  const handleScroll = useCallback(() => {
    setScrolled(!!window.scrollY);
  }, []);

  const providedContext = useMemo(() => ({
    refetchTransactions
  }), [refetchTransactions]);

  const handleListItemClick = useCallback((itemId) => {
    setEnvVars({ transactionId: itemId });
  }, [setEnvVars]);

  const handleItemDetailClose = useCallback(() => {
    setEnvVars({ transactionId: null, editItem: null });
  }, [setEnvVars]);

  useEffect(() => {
    if (transactionsFetching
      || transactionsData.length
      || !transactionsStats.current) {
      return;
    }
    setEnvVars({ page: Math.ceil(transactionsStats.current / limit) });
  }, [
    page,
    limit,
    transactionsFetching,
    transactionsData.length,
    transactionsStats,
    setEnvVars
  ]);

  useEffect(() => {
    setSelectedTransactions((prevState) => {
      return prevState.filter((id) => Utils.arrayFindById(transactionsData, id));
    });
  }, [setSelectedTransactions, transactionsData]);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page]);

  if (czCountry) return <Redirect to={`/${businessId}${UiRoutes.DOCUMENTS}`} />;

  return (
    <TransactionsContext.Provider value={providedContext}>
      {selectedTransactions.length
        ? (
          <BulkActionsComponent
            selectedTransactions={selectedTransactions}
            selectedTransactionsData={selectedTransactionsData}
            localReasons={localReasons}
            onReasonBlur={handleReasonBlur}
            onCancel={onBulkActionsCancel}
            onEdit={onBulkActionsEdit} />
        )
        : <Filters />}
      <div className={Css.transactionsPage}>
        {!userData.guestUser && (
          <div className={CommonCss.mobileContainer}>
            <TransactionsStatusFilter className={Css.statusFilter} />
          </div>
        )}
        {!transactionsFetching && !transactionsData.length && !!transactionsState && (
          <EmptyState fetchingData={fetchingData} />
        )}
        {accounts.map((account, index) => {
          const transactions = transactionsData.filter((transaction) => transaction.account.id === account.id);

          return (
            <Fragment key={account.id || index}>
              <div
                className={classNames(
                  Css.accountTitle,
                  scrolled && Css.shadow,
                  (hasFilters || !!selectedTransactions.length) && Css.withMargin
                )}>
                <div className={CommonCss.mobileContainer}>
                  <Icons.CreditCard />
                  <div className={Css.name}>{account.name}</div>
                </div>
              </div>
              <div className={CommonCss.mobileContainer}>
                {transactions.map((transaction) => (
                  <ListItem
                    key={transaction.id}
                    selectable={editableTransactionsIds.includes(transaction.id)}
                    readyToProcess={!!Utils.arrayFindById(transactionsReadyToProcess, transaction.id)}
                    readyToReview={!!Utils.arrayFindById(transactionsReadyToReview, transaction.id)}
                    readyToApprove={!!Utils.arrayFindById(transactionsReadyToApprove, transaction.id)}
                    closedBookDate={closedBookDateTransactionIds.includes(transaction.id)}
                    selected={selectedTransactions.includes(transaction.id)}
                    localReasons={localReasons}
                    transaction={transaction}
                    transactionState={Utils.arrayFindById(transactionsState, transaction.id)}
                    refetchTransactions={refetchTransactions}
                    onItemClick={handleListItemClick}
                    onSelect={onSelectedChange}
                    onChange={handleTransactionChange}
                    onReasonBlur={handleReasonBlur} />
                ))}
              </div>
            </Fragment>
          );
        })}
        {(!businessOrganization || selectedBusinessHasBusinessUsers) && (
          <AskClientPopup
            disabled={fetchingData}
            statusFilter={envVars.status}
            transactionIds={selectedNeedReactionIds}
            onClose={onAskClientPopupClose} />
        )}
        <TransactionsPopup
          disabled={transactionsFetching}
          popupMode={popupMode}
          transactionsForPopup={transactionsForPopup}
          onSubmit={onTransactionsPopupSubmit} />

        {(transactionsStats.current > Number(limit)) && (
          <Pagination
            showFirstLast={false}
            className={Css.pagination}
            count={transactionsStats.current}
            page={Number(page)}
            maxDisplayedPages={MAX_DISPLAYED_PAGES}
            pageSize={Number(limit)}
            disabled={fetchingData}
            onChange={handlePageChange} />
        )}
        {!!editItemId && (
          <ListItemDetails
            selected={selectedTransactions.includes(editItemId)}
            selectable={editableTransactionsIds.includes(editItemId)}
            readyToProcess={!!Utils.arrayFindById(transactionsReadyToProcess, editItemId)}
            readyToReview={!!Utils.arrayFindById(transactionsReadyToReview, editItemId)}
            readyToApprove={!!Utils.arrayFindById(transactionsReadyToApprove, editItemId)}
            localReasons={localReasons}
            transactionId={editItemId}
            transactionData={editTransactionData}
            transactionState={editTransactionState}
            refetchTransactions={refetchTransactions}
            onClose={handleItemDetailClose}
            onChange={handleTransactionChange}
            onReasonBlur={handleReasonBlur}
            onSelect={onSelectedChange} />
        )}
        <Comments />
        {fetchingData && <Preloader absolute />}
      </div>
    </TransactionsContext.Provider>
  );
};

export default React.memo(TransactionsPage);

export { TransactionsContext };
